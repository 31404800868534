import React from "react";
import {
  Image,
  HStack,
  Heading,
  VStack,
  Box,
  Text,
  SimpleGrid,
  Divider,
} from "@chakra-ui/react";
import ScreenSection from "./ScreenSection";
import { Card, CardHeader, CardBody, CardFooter } from "@chakra-ui/card";

const EducationSection = () => (
  <ScreenSection backgroundColor="#E3F9E5" minHeight="50vh" p={8}>
    <Heading as="h1" id="education-section" p={8}>
      Education
    </Heading>
    <SimpleGrid
      templateColumns="1fr 0.9fr"
      spacing={8}
      justifyContent="center"
      px={0}
    >
      <Card bg="#fff" borderRadius="10px" p={8} width="100%">
        <CardHeader p={2}>
          <Heading size="md">
            Bachelor in Electronics, Communication and Information Engineering
          </Heading>
        </CardHeader>
        <Divider style={{ borderColor: "black" }} />
        <CardBody>
          <Text>
            Pulchowk Campus, Institute of Engineering, Tribhuvan University
          </Text>

          <Text> Lalitpur, Nepal</Text>
          <Text>2018-2023</Text>
        </CardBody>
      </Card>
      <Card bg="#fff" borderRadius="10px" p={8}>
        <CardHeader p={2}>
          <Heading size="md">Higher Secondary Education in Science</Heading>
        </CardHeader>
        <Divider style={{ borderColor: "black" }} />
        <CardBody>
          <Text>Pinnacle Academy</Text>
          <Text> Lalitpur, Nepal</Text>
          <Text>2016-2018</Text>
        </CardBody>
      </Card>
    </SimpleGrid>

    {/* <VStack>
      <VStack>
        <Heading>
          Bachelor in Electronics, Communication and Information Engineering
        </Heading>
      </VStack>
      <VStack>
        <Heading>+2 in Science</Heading>
      </VStack>
    </VStack> */}

    {/* <VStack p={3}>
      <VStack p={1}>
        <Heading as="h2">
          Bachelor in Electronics, Communication and Information Engineering
        </Heading>
        <Text>Pulchowk Campus</Text>
        <Text>75.49 %</Text>
      </VStack>
      <VStack p={1}>
        <Heading as="h2">+2 in Science</Heading>
        <Text>Pinnacle Academy</Text>
        <Text>3.52 cgpa</Text>
      </VStack>
    </VStack> */}
  </ScreenSection>
);

export default EducationSection;
