import React from "react";
import FullScreenSection from "./FullScreenSection";
import { Box, Heading, HStack, Image, Text, VStack } from "@chakra-ui/react";
import Card from "./Card";
import { faCertificate } from "@fortawesome/free-solid-svg-icons";

const certificates = [
  {
    title: "Meta Back-End Developer",
    description:
      "Back-End Development | Python | Databases | Django Web Framework | APIs | Full Stack | Version Control",
    getImageSrc: () => require("../images/meta.png"),
    url: "https://www.credly.com/badges/bd035248-c529-47ee-913d-3181df02893d/public_url",
  },
  {
    title: "AWS Academy Graduate - AWS Academy Cloud Foundations",
    description: "EC2 | S3",
    getImageSrc: () => require("../images/AWS-certificate.png"),
    url: "https://www.credly.com/badges/4787c312-7474-4e8d-898d-3acd476acd06/public_url",
  },
];

const CertificatesSection = () => {
  return (
    <FullScreenSection backgroundColor="#F4EFFF" p={8} spacing={8}>
      <Heading as="h1" id="certificates-section">
        Certificates
      </Heading>
      <Box
        display="grid"
        gridTemplateColumns="repeat(2,minmax(0,1fr))"
        gridGap={8}
      >
        {certificates.map((certificate) => (
          <Card
            key={certificate.title}
            title={certificate.title}
            description={certificate.description}
            imageSrc={certificate.getImageSrc()}
            url={certificate.url}
            icon={faCertificate}
          />
        ))}
      </Box>
    </FullScreenSection>
  );
};

export default CertificatesSection;
