import React from "react";
import { Box, Flex, VStack, Text, HStack } from "@chakra-ui/react";
import ScreenSection from "./ScreenSection";
import { faFilePdf, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
  return (
    <ScreenSection
      backgroundColor="#000"
      isDarkBackground
      minHeight="20vh"
      p={8}
    >
      <Box>
        <footer>
          <VStack>
            <HStack
              spacing={24}
              margin="0 auto"
              px={12}
              color="white"
              justifyContent="center"
              alignItems="center"
              maxWidth="1024px"
              height={24}
            >
              <Text color="lightblue" fontSize="2xl">
                Contact mail : rouniyar.rahul27@gmail.com{"  "}
                <a href="mailto: rouniyar.rahul27@gmail.com">
                  <FontAwesomeIcon icon={faEnvelope} />
                </a>
              </Text>
              <Text color="lightblue" as="u" fontSize="2xl">
                Download Resume{"  "}
                <a href={require("../pdf/Resume.pdf")} download>
                  <FontAwesomeIcon icon={faFilePdf} />
                </a>
              </Text>

              <p> </p>
            </HStack>
            <p>Rahul • © 2024</p>
          </VStack>
        </footer>
      </Box>
    </ScreenSection>
  );
};
export default Footer;
