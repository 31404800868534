import React from "react";
import FullScreenSection from "./FullScreenSection";
import { Box, Heading } from "@chakra-ui/react";
import Card from "./Card";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

const projects = [
  {
    title: "Gesture Synthesis",
    description:
      "A gesture synthesis model using Multimodal Supervised Learning that synthesizes human gestures for a given audio.",
    getImageSrc: () => require("../images/SpeechtoGesture.png"),
    url: "https://github.com/rahulrouniyar/speechToGesture",
  },
  {
    title: "Biometric Voting System",
    description:
      "A desktop application for election authority to handle all activities related to voting such as voter registration, candidate registration, voter managements,etc.",
    getImageSrc: () => require("../images/bvms.png"),
    url: "https://github.com/rahulrouniyar/biometricVotingSystem",
  },
  // {
  //   title: "Photo Gallery",
  //   description:
  //     "A One-stop shop for photographers to share and monetize their photos, allowing them to have a second source of income",
  //   getImageSrc: () => require("../images/photo3.jpg"),
  // },
  // {
  //   title: "Event planner",
  //   description:
  //     "A mobile application for leisure seekers to discover unique events and activities in their city with a few taps",
  //   getImageSrc: () => require("../images/photo4.jpg"),
  // },
];

const ProjectsSection = () => {
  return (
    <FullScreenSection backgroundColor="#FFF5E6" p={8} spacing={8}>
      <Heading as="h1" id="projects-section">
        Featured Projects
      </Heading>
      <Box
        display="grid"
        gridTemplateColumns="repeat(2,minmax(0,1fr))"
        gridGap={8}
      >
        {projects.map((project) => (
          <Card
            key={project.title}
            title={project.title}
            description={project.description}
            imageSrc={project.getImageSrc()}
            url={project.url}
            icon={faGithub}
          />
        ))}
      </Box>
    </FullScreenSection>
  );
};

export default ProjectsSection;
